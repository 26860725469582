.signOut {
  .menuItem {
    font-size: 16px;
    color: #383838;
    padding: 24px;
    &:hover {
      background: #eaf5ff;
      color: #095797;
    }
  }
}
