@import url('https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css');

.form-control {
    -webkit-transition: none;
    transition: none;
    width: 55px;
    height: 55px;
    text-align: center;
    font-family: "Poppins";
    font-size: 24px;
    font-weight: bold;
}

.form-control:focus {
    color: #00A2B2;
    background-color: #ffffff;
    border-color: #00A2B2;
    outline: 0;
}

.form-control.form-control-solid {
    border: 1px solid;
    background-color: #ffffff;
    border-color: #001F22;
    color: #00A2B2;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}

.margin-right {
    margin-right: 30px;
}


.form-control.form-control-solid:active,
.form-control.form-control-solid.active,
.form-control.form-control-solid:focus,
.form-control.form-control-solid.focus {
    background-color: #ffffff;
    border-color: #00A2B2;
    color: #00A2B2;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}