.form {
  .textFieldContainer {
    margin: 5px 0px;
  }
  .residential:disabled {
    text-transform: capitalize;
    color: #383838;
    background: #d7edff;
    border-radius: 4px;
    box-shadow: none;
  }
}
