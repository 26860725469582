.left-background {
    position: absolute;
    top: 0px;
    left: 0px;
}

.right-background {
    position: absolute;
    top: 0px;
    right: 0px;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

@media screen and (max-width: 1300px) {
    .left-background {
        left: -150px;
    }

    .right-background {
        right: -150px;
    }
}

@media screen and (max-width: 900px) {
    .left-background {
        left: -250px;
    }

    .right-background {
        right: -250px;
    }
}